import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It has been a busy and exciting year for PayPerks and our team. We want to thank you for your help and support along the way.`}</p>
    <p>{`We took a short break this holiday season to do a little dancing and to wish you Happy Holidays and a healthy, happy, successful...and groovy 2012.`}</p>
    <p><em parentName="p">{`(YouTube link)`}</em>{` `}<a parentName="p" {...{
        "href": "http://www.youtube.com/watch?v=XKkgtCIqaSs"
      }}>{`Happy Holidays & New Year from PayPerks`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      